<template>
  <v-row>
    <v-col cols="12">
      <v-textarea
        outlined
        dense
        v-model="product.preview_text"
        :label="$t('preview.text')"
      />
    </v-col>
    <v-col cols="12">
      <vue-editor v-model="product.description"></vue-editor>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";
import type { Product } from "@planetadeleste/vue-mc-shopaholic";

@Component({
  components: {
    VueEditor,
  },
})
export default class FormTabDescription extends Vue {
  @VModel({ type: Object, default: false }) product!: Product;
}
</script>
